import { useDispatch, useSelector } from "react-redux";
import { mobileStyle } from "../../Home";
import NewCardLayout from "../../atoms/NewCardLayout/NewCardLayout";
import HeaderV2 from "../../molecules/Header/HeaderV2";
import { useNavigate } from "react-router-dom";
import newDeleteIcon from "../../../../images/newDeleteIcon.svg";
import React, { useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import NeedHelp from "../../atoms/NeedHelp/NeedHelp";
import NewFooter from "../../molecules/NewFooter/NewFooter";
import userServices from "../../../../services/user-services";
import { getFavouriteItems } from "../../../../redux/favouritesReducer";
import LoadingScreen from "../../LoadingScreen/Loading";
import ShopMore from "../../molecules/ShopMore/ShopMore";
import constants from "../../../../constants/en";
import NewButton from "../../atoms/NewButton/NewButton";
import classes from './Favourites.module.css'
import AuthContext from "../../../../store/auth-context";
import HeaderV3 from "../../molecules/Header/HeaderV3/HeaderV3";
import { getDisplayPrice } from "../../../../utils/displayPrice";

const Favourites = () => {
    const primaryBackgroundColor = "var(--pure-white)"
    const primaryColor = mobileStyle.color[1]
    const { data: favouriteItems, loading } = useSelector(state => state.favourites)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {userId, cusId, cardId, isSavedShip}  = useContext(AuthContext)
    const isTablet = useMediaQuery({
        maxWidth: 768
    })
    const isMobile = useMediaQuery({
        maxWidth:500
    })
    useEffect(() => {
        dispatch(getFavouriteItems())
    }, [])
    const clickHandler = (item) => {
        let xrefQuery = ''
        if (item?.auction_id !== item?.auction_item?.auction_id) {
            xrefQuery = `&xref_auction_id=${item?.auction_id}`
        }
        navigate(
            `/lot/${item?.auction_item?.id}?auction_id=${item?.auction_item?.auction.id}&cause_id=${item?.auction_item?.auction.cause_id}${xrefQuery}`
        );

    };
    const handleRemove = async (event, item) => {
        try {
            event.stopPropagation()
            await userServices.removeFromFavourites(item?.id)
            dispatch(getFavouriteItems())
        } catch (error) {
            throw error;
        }
    }
    const getVendorSKUId = (item) => {
        let vendorSkuId;
        if(item?.auction_item?.store_item) {
            if (item.auction_item?.store_item?.variant) {
                vendorSkuId = item.auction_item.store_item.variant?.vendor_sku_id
            }
            else {
                vendorSkuId = item.auction_item.store_item?.vendor_sku_id
            }
        }
        else {
            vendorSkuId = item.auction_item?.vendor_sku_id
        }
        return vendorSkuId;
    }
    const getUPC = (item) => {
        let itemUPC;
        if(item?.auction_item?.store_item) {
            if (item.auction_item?.store_item?.variant) {
                itemUPC = item.auction_item.store_item.variant?.upc
            }
            else {
                itemUPC = item.auction_item.store_item?.upc
            }
        }
        else {
            itemUPC = item.auction_item?.upc
        }
        return itemUPC;
    }
    const getPrice = (item) => {
        if (item?.auction_item?.store_item) {
            if (item.auction_item.store_item.variant) {
                return {
                    price: Number(getDisplayPrice(item.auction_item)).toFixed(2),
                    label: 'Price'
                }
            }
            else {
                return {
                    price: Number(getDisplayPrice(item.auction_item)).toFixed(2),
                    label: 'Price'
                }
            }
        }
        else {
            // let currentBid = props?.highestBid && props?.highestBid[0]?.bid_amount ? props?.highestBid[0]?.bid_amount : price
            if (item?.auction_item?.highestBid?.length) {
                return {
                    price: item?.auction_item?.highestBid[0]?.bid_amount && Number(item?.auction_item?.highestBid[0]?.bid_amount).toFixed(2),
                    label: 'Current Bid'
                }
            }
            else {
                return {
                    price: Number(item?.auction_item?.price).toFixed(2),
                    label: 'Opening Bid'
                }
            }
        }
    }
    const checkItemAvailability = (item) => {
        if(item?.auction_item?.status === 'sold') {
            return false
        }
        if(item?.auction_item?.store_item) {
            if(item?.auction_item?.store_item?.variant?.quantity_in_stock === 0) {
                return false
            }
            else if(item?.auction_item?.store_item?.quantity_in_stock === 0) {
                return false
            }
        }
        else if(new Date(item?.auction_item?.end_date) < new Date() ) {
            return false
        }
        return true
    }
    const getPurchaseDetails = (item) => {
        if(item?.auction_item?.store_item) {
            return {
                buttonText: 'Buy Now',
                handlePurchaseClick: purchaseStoreItemOrBuyNowItem
            }
        }
        else {
            // if(+item?.auction_item?.buy_now_price > 0) {
            //     return {
            //         buttonText: 'Buy Now',
            //         handlePurchaseClick: purchaseStoreItemOrBuyNowItem
            //     }
            // }else {
            //     return {
            //         buttonText: 'Place Bid',
            //         handlePurchaseClick: handleAuctionItem
            //     }
            // }
            return {
                buttonText: 'Place Bid',
                handlePurchaseClick: handleAuctionItem
            }
        }
    }
    const getPurchasePrice = (item) => {
        if(+item?.auction_item?.buy_now_price > 0) {
            return Number(item?.auction_item?.buy_now_price).toFixed(2)
        }
        else {
            return getPrice(item).price
        }
    }
    const purchaseStoreItemOrBuyNowItem = (event, item) => {
        event.stopPropagation()
        const auctionItem = item.auction_item;
        // create purchase details state
        let purchaseState = {
            type: 'buy_now',
            user_id: userId,
            store_item_id: auctionItem?.store_item_id ? auctionItem?.store_item_id : null,
            item_name: auctionItem?.name,
            feat_img: auctionItem?.assets && auctionItem?.assets?.find(img=>img.context_type==="portrait")?.name,
            description: auctionItem?.description,
            price: getPurchasePrice(item),
            product_sku_id: item?.item_sku,
            is_virtual: auctionItem?.is_virtual,
            itemId: auctionItem.id,
            auctionId: item?.auction_id,
            causeId: item?.cause_id,
            auction:item?.auction,
            fulfilment: auctionItem?.fulfilment,
            quantity: 1,
            charge_tax: auctionItem?.store_item_id ? auctionItem?.store_item?.charge_tax : 1,
            cause_id: item?.cause_id,
            causeSharePercentage: ((Number(auctionItem?.donor?.revenue_splits[0]?.cause_rev_share_percent) / 100) || 0.8),
            productWeight: auctionItem?.weight,
            variant: item?.auction_item?.store_item?.option ? item?.auction_item?.store_item?.option : null,
            vendor_sku_id: getVendorSKUId(item),
            upc: getUPC(item)
        }
        if(cardId && cusId) {
            navigate( isSavedShip ? '/bag' : '/shipping-info', {state: purchaseState})
        }
        else {
            navigate( '/payment', {state: purchaseState})
        }
    }
    const handleAuctionItem = (event, item) => {
        event.stopPropagation()
        let auctionPurchaseState = {
            type: 'bid',
            itemId: item?.auction_item?.id,
            auctionId: item?.auction_id,
            causeId: item?.cause_id,
            cause_id: item?.cause_id,
        }
        if(cardId && cusId) {
            if(!isSavedShip) {
                navigate( '/shipping-info',{state: auctionPurchaseState})
            }
            else {
                navigate( `/lot/${item?.auction_item?.id}/place-bid?cause_id=${item?.cause_id}&auction_id=${item?.auction_id}`,{state: auctionPurchaseState})
            }
        }
        else {
            navigate('/payment', {state: auctionPurchaseState})
        }
    }
    return (
        <>
            <div className="cart-outer-container">
                {/* <HeaderV2 logoType='black2' primaryBackgroundColor={primaryBackgroundColor} primaryColor={primaryColor} containerStyle={{ position: "fixed" }} isSubHeader subHeaderTitle="Favorites" /> */}
                <HeaderV3 primaryBackgroundColor={primaryBackgroundColor} isSubHeader subHeaderTitle="Favorites" position="absolute" />
                {loading && (
                    <LoadingScreen bgColor="#F0E8E3" />
                )}
                <div className={` ${isTablet ? "bag-container" : "margin_class"}`} style={{ padding: isTablet ? "55px 5vw" : "", minHeight: isTablet ? "95vh" : "52vh", backgroundColor: primaryBackgroundColor, paddingTop: isTablet ? "170px" : "200px" }}>
                    {(favouriteItems.length === 0) ? (
                        <ShopMore title={constants.APP_COPY_FAVOURITE_EMPTY_MESSAGE} />

                    ) : (
                        favouriteItems.map((item, i) => {
                            return (
                                <React.Fragment key={item.id}>

                                    {/* product card*/}

                                    <NewCardLayout imageContainerStyle={{ width: !isTablet && '150px', height: !isTablet && '150px' }} onCardClick={() => clickHandler(item)} image={item.auction_item.assets.find(img => (img.context_type === 'thumbnail' || img.context_type === 'portrait'))?.name} neededLine={true}>
                                        <div className="cart-card-content">
                                            <h1 >{item.auction_item?.name}</h1>
                                            <div style={{ marginTop: "-10px", fontSize: "12px", display: "flex", gap: "10px", marginBottom: "10px" }}>
                                                {item?.auction_item?.store_item &&
                                                    item.auction_item.store_item.variant &&
                                                    item.auction_item.store_item.option ? (
                                                    Object.keys(item.auction_item.store_item.option).map((key, index) => (
                                                        <span key={index} style={{ color: primaryColor }}>{key}: {item.auction_item.store_item.option[key]}</span>
                                                    ))
                                                ) : null
                                                }
                                            </div>

                                            <div className="cart-card-qty_price">
                                                <div className={`cart-card-remove_price ${classes.priceContainer}`}>
                                                    {/* <h5 style={{ color: primaryColor, fontWeight: "600" }} >
                                                        ${getPrice(item).price}
                                                    </h5> */}
                                                    
                                                    <p className={classes.priceText}>
                                                        <b>{getPrice(item).label}:</b> ${getPrice(item).price}
                                                    </p>
                                                    {
                                                        +item?.auction_item?.buy_now_price > 0 &&
                                                        <p className={classes.priceText}>
                                                            <b>Buy Now:</b> ${Number(+item?.auction_item?.buy_now_price).toFixed(2)}
                                                        </p>
                                                    }
                                                </div>
                                            </div>
                                            {
                                                item?.auction_item?.store_item ?
                                                <div style={{ marginTop: "10px", marginBottom: "5px", fontSize: "12px", }}>
                                                    {
                                                        (item.auction_item.store_item?.variant) ?
                                                            (item.auction_item.store_item?.variant?.quantity_in_stock < 5 &&
                                                                item.auction_item.store_item?.variant?.quantity_in_stock > 0) ?
                                                                <span style={{ color: mobileStyle.color[0] }}>Only {item.auction_item.store_item?.variant?.quantity_in_stock} left - order soon</span> :
                                                                (item.auction_item.store_item?.variant?.quantity_in_stock === 0) ?
                                                                    <span style={{ color: mobileStyle.color[0] }}>Sorry this item is now sold out</span> :
                                                                    ""
                                                            :
                                                            (item.auction_item.store_item?.quantity_in_stock < 5 &&
                                                                item.auction_item.store_item?.quantity_in_stock > 0) ?
                                                                <span style={{ color: mobileStyle.color[0] }}>Only {item.auction_item.store_item?.quantity_in_stock} left - order soon</span> :
                                                                (item.auction_item.store_item?.quantity_in_stock === 0) ?
                                                                    <span style={{ color: mobileStyle.color[0] }}> Sorry this item is now sold out </span> :
                                                                    ""
                                                    }
                                                </div>
                                                : <div  style={{ marginTop: "5px", marginBottom: "5px"}}></div>
                                            }
                                            <div className={classes.favouriteCardActions}>
                                               {  !isMobile &&
                                                  <NewButton onClick={() => clickHandler(item)} text={'View Details'} backgroundColor={primaryBackgroundColor} color={primaryColor} className="favouriteButtons" />
                                               }
                                               {
                                                 checkItemAvailability(item) && 
                                                 <>
                                                   <NewButton text={getPurchaseDetails(item).buttonText} onClick={(event) => getPurchaseDetails(item).handlePurchaseClick(event, item)} backgroundColor={primaryColor} color={primaryBackgroundColor} className="favouriteButtons" />
                                                   {
                                                    (!item?.auction_item?.store_item_id && +item?.auction_item?.buy_now_price > 0) && 
                                                    <NewButton text={'Buy Now'} onClick={(event) => purchaseStoreItemOrBuyNowItem(event, item)} backgroundColor={primaryBackgroundColor} color={primaryColor} className="favouriteButtons" />
                                                   }
                                                 </>
                                               }
                                            </div>
                                        </div>
                                        <button
                                            className="remove-btn"
                                            onClick={(e) => handleRemove(e,item)}>
                                            <img src={newDeleteIcon} alt="" />
                                        </button>
                                    </NewCardLayout>

                                </React.Fragment>
                            );
                        })
                    )}
                    {
                        favouriteItems.length ?
                            <NeedHelp style={{ marginTop: "60px" }} primaryColor={primaryColor} />
                            : null
                    }
                </div>
                <div style={{ width: '100%', backgroundColor: '#F0E8E3' }}>
                    <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />

                </div>
            </div>
        </>
    )
}

export default Favourites;