import React from 'react'
import classes from './NewFilterButton.module.css'
import { CircularProgress } from '@mui/material'

const NewFilterButton = ({type='button', text, onClick, backgroundColor="transparent", color="#fff",loading = false,success=false, style={}, className='button', id='basic-button', ariaControls='', haspopup='false', expanded=undefined, Icon }) => {
  return (
        <button
         id={id}
         aria-controls={ariaControls}
         aria-haspopup={haspopup}
         aria-expanded={expanded}
         type={type}
         className={`${classes.button} ${classes[className]}`}
         style={{ backgroundColor:backgroundColor, color:color, ...style}}
         onClick={onClick} 
         > {loading ? <CircularProgress color='inherit'/> : text}
         {Icon ? <Icon className={classes.buttonIcon}/> : null}
         </button>
  )
}

export default NewFilterButton