import React from 'react'
import classes from './NewButton.module.css'
import { CircularProgress } from '@mui/material'

const NewButton = ({ type = 'button', text, onClick, backgroundColor = "transparent", color = "#F0E8E3", loading = false, success = false, addToCartLoading = false, style = {}, className = 'button', id = 'basic-button', ariaControls = '', haspopup = 'false', expanded = undefined, Icon }) => {
  return (
    <button
      id={id}
      aria-controls={ariaControls}
      aria-haspopup={haspopup}
      aria-expanded={expanded}
      type={type}
      className={`${classes.button} ${classes[className]}`}
      style={{ border: `2px solid ${color}`, backgroundColor: backgroundColor, color: color, ...style }}
      onClick={onClick}
      disabled={addToCartLoading}
    > {loading || addToCartLoading ? <CircularProgress color='inherit' /> : text}
      {Icon ? <Icon className={classes.buttonIcon} /> : null}
    </button>
  )
}

export default NewButton