import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {useSelector} from 'react-redux';
import userServices from "../../../../services/user-services";
import AuthContext from "../../../../store/auth-context";
// import GlobalHeader from "../../molecules/GlobalHeader";
// import MailSVG from "../../../../images/mail.svg";
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// import { Desktop, Mobile } from "../../responsive/Responsive";
// import Navbar from "../../../desktop/components/Navbar/Navbar";
// import Header from "../../../desktop/components/Header/Header";
// import Footer from "../../../desktop/components/Footer/Footer";
// import SubNavbar from "../../../desktop/components/SubNavbar/SubNavbar";
// import BackContainer from "../../../desktop/components/BackContainer/BackContainer";
// import { Backdrop, CircularProgress } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getCartItems } from "../../../../redux/cartReducer";
import {useDispatch} from 'react-redux';
import { InitiateCheckout } from "../../../../utils/meta-pixel-tracking";
// import HeaderV2 from "../../molecules/Header/HeaderV2";
import HeaderV3 from "../../molecules/Header/HeaderV3/HeaderV3";
// import SubHeader from "../../molecules/SubHeader/SubHeader";
import { mobileStyle } from "../../Home";
import { useMediaQuery } from "react-responsive";
import newDeleteIcon from "../../../../images/newDeleteIcon.svg";
import NewButton from "../../atoms/NewButton/NewButton";
import NeedHelp from "../../atoms/NeedHelp/NeedHelp";
import NewCardLayout from "../../atoms/NewCardLayout/NewCardLayout";
import LoadingScreen from "../../LoadingScreen/Loading";
import { clearPageNumber } from "../../../../redux/itemsReducer";
import { Tooltip } from '@mui/material';
// import MobileSubFooter from "../../atoms/MobileSubFooter/MobileSubFooter";
import NewFooter from "../../molecules/NewFooter/NewFooter";
import ConfirmAlertLogin from "../../ConfirmAlertLogin";
import { getDisplayPrice, getDisplayVariantPrice } from "../../../../utils/displayPrice";

const ShopMore= ()=> {
  const isMobile = useMediaQuery({
    maxWidth: 768
   })
   const dispatch = useDispatch();
   const navigate = useNavigate();
   return(
      <div style={{ textAlign: 'center' }}>
          <button style={{width:isMobile?"":"100px"}} onClick={() => {
            dispatch(clearPageNumber());
            // navigate("/browse-items", {
            //   state: { buyNow: true, title: "Buy now", from: 'buyNow' },
            // })
            navigate('/shop-now')
          }

        } className='buy-checkout-btn-outline'>Keep shopping</button>
      </div>
      )
}

const CartOverView = () => {

  const [price, setPrice] = useState(0);
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [updateQty,setUpdateQty] = useState(false);
  const [checkoutDetails,setCheckoutDetails] = useState({})
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {data:cartItems,itemRemoved}=useSelector(state=>state.cartDetails)
  const primaryBackgroundColor = "var(--pure-white)"
  const primaryColor = mobileStyle.color[1]
  const redColor = mobileStyle.backgroundColor[0]
  const isMobile = useMediaQuery({
   maxWidth: 768
  })
  const handleCloseConfirmAlert = () => {
    setOpenConfirmAlert(false);
  };
  useEffect(() => {
    // if(!authCtx.isLoggedIn){
    //   return
    // }
    setIsLoading(true)
    dispatch(getCartItems({loggedIn: authCtx.isLoggedIn}))

      setIsLoading(false)
    
  }, [updateQty]);

  useEffect(() => {
    // if(!authCtx.isLoggedIn){
    //   return
    // }
    if(!cartItems?.length) return;
    setIsLoading(true)
    const totalprice = (cartItems) => {
      let sum = 0;
      for (let i = 0; i < cartItems.length; i++) {
        if (cartItems[i]?.auction_item?.store_item?.variant) {
          if(cartItems[i]?.auction_item?.store_item?.variant?.quantity_in_stock >= cartItems[i].quantity){
            sum +=(Number(+cartItems[i]?.quantity)) * ( Number(getDisplayVariantPrice(cartItems[i]?.auction_item?.store_item?.variant, cartItems[i]?.auction_item)));
          }

        } else {
          if(cartItems[i]?.auction_item?.store_item?.quantity_in_stock >= cartItems[i].quantity){
            sum +=(Number(+cartItems[i]?.quantity)) * ( Number(+getDisplayPrice(cartItems[i]?.auction_item)));
          }     
        }
      }
      return sum;
    };


    setPrice(totalprice(cartItems));
    setIsLoading(false)

    let content_ids=[]
    let contents=[]
   cartItems.map(item=>{
    content_ids.push(item.auction_item_id)
    contents.push({
      name:item.auction_item.name,
      id:item.auction_item_id,
      sku:item.item_sku,
      quantity:item.quantity,
      auction_id: item?.auction_id
    })
       return null 
      }

    )

    setCheckoutDetails({content_ids,contents,num_items:cartItems.length,value:totalprice(cartItems)})
  }, [cartItems]);



  const handleRemove = async (id,idx) => {
    await userServices
      .removeFromCart(id, authCtx.isLoggedIn)
      .then((res) => {
        dispatch(getCartItems({loggedIn: authCtx.isLoggedIn}))
      })
      .catch((err) => {
        throw err;
      });
     
  };

  const clickHandler = (i) => {
    navigate(
      `/lot/${i?.auction_item?.id}?auction_id=${i?.auction_item?.auction.id}&cause_id=${i?.auction_item?.auction.cause_id}`
    );
  };

  const handleQtyIncrement =async (item) => {
    if(item?.auction_item?.store_item?.variant){
      if(item?.auction_item?.store_item?.variant?.quantity_in_stock === item.quantity){
        return
      }else{
        await userServices.updateQuantity({quantity: item?.quantity + 1},item.id, authCtx.isLoggedIn)
      }
    }else{
      if(item?.auction_item?.store_item?.quantity_in_stock === item.quantity){
        return 
      }else{
        await userServices.updateQuantity({quantity: item?.quantity + 1},item.id, authCtx.isLoggedIn)
      }
    }
   
    setUpdateQty(!updateQty)
  }

  const handleQuantityDecrement = async (item) =>{
    if(item?.quantity <= 1){
        return
    }else{
      await userServices.updateQuantity({quantity: item?.quantity - 1},item.id, authCtx.isLoggedIn)
    }
    setUpdateQty(!updateQty)
  }

  const checkout = ()=>{
    if(!authCtx.isLoggedIn) {
      setOpenConfirmAlert(true);
      return
    }
    //   //meta pixel tracking
    InitiateCheckout(checkoutDetails)
    navigate("/cart-checkout")
  }

  const checkMaxQuantity = (item) =>{
    if(item?.auction_item?.store_item?.variant){
      if(item?.auction_item?.store_item?.variant?.quantity_in_stock <= item.quantity){
        return true
      }else{
        return false
      }
    }else{
      if(item?.auction_item?.store_item?.quantity_in_stock <= item.quantity){
        return true
      }else{
        return false
      }
    }
  }

  const handleContinueShoping = () =>{
    let lastCartItem = cartItems[cartItems.length - 1];
      navigate('/shop-now')
    // if(lastCartItem?.auction_item?.auction?.status !== "live" || lastCartItem?.auction_item?.auction?.is_store){
    //   return
    // }
    // setParams({auction:data?.data,image:data?.data?.image2,from:paramsData?paramsData?.from:'event',market_place:(data?.data.market_place === "1" || paramsData?.market_place === true),type:data?.data.market_place === "1"?'Marketplace':data?.data.is_store?'Store':'Event'})
    
    // let params = {
    //   auction:lastCartItem?.auction_item?.auction,
    //   image:lastCartItem?.auction_item?.auction?.image2,
    //   from:(lastCartItem?.auction_item?.auction?.market_place === "1") ? 'donor' : 'event-byNow',
    //   market_place : (lastCartItem?.auction_item?.auction?.market_place === "1"),
    //   type: (lastCartItem?.auction_item?.auction?.market_place === "1")  ? 'Marketplace' : (lastCartItem?.auction_item?.auction?.is_store) ? 'Store' : 'Event',
    //   title:lastCartItem?.auction_item?.auction?.title
    // }
    // navigate("/browse-items", {
    //   state: { ...params }
    // })
  }
  return (
  <div className="cart-outer-container">
  
    {/* <GlobalHeader title="My Cart" /> */}
    {/* <HeaderV2 logoType='black2' primaryBackgroundColor={primaryBackgroundColor} primaryColor={primaryColor} containerStyle={{position:"fixed"}} isSubHeader subHeaderTitle="Cart"/> */}
    <HeaderV3 primaryBackgroundColor={primaryBackgroundColor} isSubHeader subHeaderTitle="Cart" position="absolute"/>
 
   {isLoading && (
        <LoadingScreen bgColor="#F0E8E3"/>
      )}
   <div className={` ${isMobile ? "bag-container" : "margin_class"}`} style={{ padding:isMobile ? "55px 5vw" : "", minHeight: isMobile ? "95vh" : "52vh", backgroundColor: primaryBackgroundColor,paddingTop:isMobile?"170px":"200px" }}>
  
   <div>
   {/* {itemRemoved && <p className="alert1"> <InfoOutlinedIcon/> <span>One or more item from your cart have been removed due to unavailability</span></p>} */}
   {(cartItems.length === 0  ) ? (

      <div className="bag-container-empty" style={{marginTop:isMobile?"100px":"" }}>
      <div className="no-items">Your Cart Is Empty</div>
      <ShopMore/>
    </div>

        ) : (
          cartItems.map((item, i) => {
            return (    
              <React.Fragment key={item.id}>
                 
                    {/* product card*/}

                   <NewCardLayout imageContainerStyle={{width:!isMobile && '150px', height:!isMobile && '150px'}} onClick={() => clickHandler(item)} image={item.auction_item.assets.find(img=>(img.context_type==='thumbnail'||img.context_type==='portrait'))?.name} neededLine={true}>
                   <div className="cart-card-content" key={item.id}>
                     <h1 >{item.auction_item?.name}</h1>
                     <div style={{marginTop: "-10px", fontSize: "12px", display: "flex", gap: "10px", marginBottom: "10px"}}>
                       {item.auction_item.store_item.variant &&  item.auction_item.store_item.option ? (
                         Object.keys(item.auction_item.store_item.option).map((key) => (
                           <span style={{color:primaryColor}}>{key}: {item.auction_item.store_item.option[key]}</span>
                         ))
                       ) : null}
                     </div>
                     
                     <div className="cart-card-qty_price">
                           <div className="cart-card-remove_price">
                              <h5 style={{color:primaryColor,fontWeight:"600"}} >
                                {item.auction_item.store_item.variant
                                  ? ` $${(item.quantity * Number(getDisplayVariantPrice(item.auction_item?.store_item?.variant, item?.auction_item))).toFixed(2)}`
                                 : ` $${(item.quantity * Number(getDisplayPrice(item.auction_item))).toFixed(2)}`}
                              </h5>
                           </div>
                     </div>
                     <div style={{marginTop: "10px", marginBottom: "5px",fontSize: "12px",}}>
                       {
                           (item.auction_item.store_item?.variant) ?  
                           
                             (item.auction_item.store_item?.variant?.quantity_in_stock < 5 && item.auction_item.store_item?.variant?.quantity_in_stock >  0) ? <span style={{color:mobileStyle.color[0]}}>Only {item.auction_item.store_item?.variant?.quantity_in_stock} left - order soon</span> : (item.auction_item.store_item?.variant?.quantity_in_stock=== 0) ? <span style={{color:mobileStyle.color[0]}}>Sorry this item is now sold out</span>:""
                           : (item.auction_item.store_item?.quantity_in_stock < 5 && item.auction_item.store_item?.quantity_in_stock > 0) ? <span style={{color:mobileStyle.color[0]}}>Only {item.auction_item.store_item?.quantity_in_stock} left - order soon</span> : (item.auction_item.store_item?.quantity_in_stock === 0) ? <span style={{color:mobileStyle.color[0]}}> Sorry this item is now sold out </span>:""
                       }
                     </div>
                   </div>
                   <div style={{display:'flex', flexDirection:"column", gap:'17px'}}>
                   <div className="mobile_quantity_wrapper" >
                    <Tooltip title={(checkMaxQuantity(item))? "You have reached maximum quantity" : ""}>
                      <span className="increment_btn" style={{backgroundColor:(checkMaxQuantity(item)? "gray":"var(--shady-white)")}}  onClick={()=>{handleQtyIncrement(item)}}>
                              <AddIcon style={{color:primaryColor, fontSize: "1rem"}}/>
                      </span>
                    </Tooltip>
                     
                          <span className="quantity_text" style={{fontSize: "1rem", fontWeight: "600"}}>
                              {item.quantity}
                          </span>
                          <span className="increment_btn" onClick={()=>handleQuantityDecrement(item)}>
                               <RemoveOutlinedIcon style={{color:primaryColor, fontSize: "1rem"}}/>
                          </span>
                    </div>
                          <button
                            className="remove-btn"
                            onClick={() => handleRemove(item.id,i)}>
                            <img src={newDeleteIcon} alt="" />
                          </button>
                    </div>
                    </NewCardLayout>
                
                  </React.Fragment>         
            );
          })
        )}
   </div>
         {
        (cartItems.length === 0 ) ? null : (price <= 0) ?              //condetion satisfy when there is only sold out items in the cart
        <div className="emptyContainerSoldItems" ><ShopMore/></div> :
      <div className="summary_container" >
      <div className="bag-order-summary">
        <h1>Order Summary</h1>
        <div className="bag-summary-grid">
          <div>
          <div style={{fontSize: "1.2rem", fontWeight: '600'}}>
            <div className="cart_subtotal_heading">Subtotal</div>
            <div className="bag-card-price">${Number(price).toFixed(2)}</div>
          </div>
          </div>
        </div>

      </div>

        {/* checkout and keepshoping buttons */}
        <div className="mobile_cart_checkout_buttons_wrapper">
        <NewButton text={'CHECKOUT'} onClick={checkout} backgroundColor={redColor} color={primaryBackgroundColor} 
          style={{width:'100%', border:'0px !important', fontSize:'16px', lineHeight:"20px", paddingBlock:'9px'}} />
        <NewButton text={'CONTINUE SHOPPING'} onClick={handleContinueShoping} backgroundColor={primaryBackgroundColor} color={redColor} 
          style={{width:'100%',  fontSize:'16px', lineHeight:"20px", paddingBlock:'9px'}} />
        </div>

        <NeedHelp style={{marginTop:"60px"}} primaryColor={primaryColor}/>
        

        </div>
      }
   </div>
   <div style={{width:'100%',backgroundColor:'#F0E8E3'}}>
      {/* <MobileSubFooter needHelp style={{padding:'6vw 5vw 2vw 5vw'}} primaryBackgroundColor="#F0E8E3" primaryColor='#30313A'/> */}
      <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />

      </div>
      <ConfirmAlertLogin
        openConfirmAlert={openConfirmAlert}
        handleCloseConfirmAlert={handleCloseConfirmAlert}
        context={{
          returnUrl:'/cart'
        }}
      />
  </div>
  );
};

export default CartOverView;
