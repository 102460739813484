export const getDisplayPrice = (item) => {
    // let price = item?.store_item?.discounted_price !== undefined && item?.store_item?.discounted_price !== null ? item?.store_item?.discounted_price : item?.store_item?.price;

    // let price = item?.store_item?.discounted_price  ? item?.store_item?.discounted_price : ( item.auction.discount_percentage ? item?.store_item?.price*(100-item.auction.discount_percentage)/100 : item?.store_item?.price )

    const discountPercentage = item?.auction_auction_item_xrefs?.[0]?.auction?.discount_percentage
        ? item?.auction_auction_item_xrefs[0].auction.discount_percentage
        : item?.auction?.discount_percentage;

    // Calculate the price using the determined discount percentage
    let price = (item?.auction?.ui === "flash_sale" || item?.auction_auction_item_xrefs?.[0]?.auction?.ui === "flash_sale")
                    ? (item?.store_item?.discounted_price 
                        ? item?.store_item?.discounted_price 
                        : (discountPercentage 
                            ? item?.store_item?.price * (100 - discountPercentage) / 100 
                            : item?.store_item?.price)
                        )
                    : item?.store_item?.price;

    return price;
};

export const getDisplayListPrice = (item) => {
    let listPrice = item?.store_item?.list_price !== undefined && item?.store_item?.list_price !== null ? item?.store_item?.list_price : item?.store_item?.price;
    return listPrice;
};

export const getDisplayVariantPrice = (selectedVariant,lotDetails) => {

    const discountPercentage = lotDetails?.auction_auction_item_xrefs?.[0]?.auction?.discount_percentage
        ? lotDetails?.auction_auction_item_xrefs[0].auction.discount_percentage
        : lotDetails?.auction?.discount_percentage;


    if(lotDetails?.auction?.ui === "flash_sale"  || lotDetails?.auction_auction_item_xrefs?.[0]?.auction?.ui === "flash_sale"){
        if( selectedVariant?.discounted_price !== "null" && selectedVariant?.discounted_price !== null){
            return selectedVariant?.discounted_price 
        }else if(discountPercentage ){
            return Number(selectedVariant?.price) * (100 - discountPercentage) / 100 
        }else{
            return Number(selectedVariant?.price)
        }
    }
    else{
        return Number(selectedVariant?.price)
    }
};